/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Subtitle, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Reference"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="mt--30 pb--0 pt--0" name={"reference"}>
          
          <ColumnWrap className="column__flex --shape4 --center el--2 pb--60 pl--0 pr--0 pt--50 flex--top" anim={null} animS={null} style={{"backgroundColor":"var(--color-custom-1)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"} style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--left fs--62 title-box--invert" content={"Reference"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null} style={{"maxWidth":510}}>
              
              <Text className="text-box" content={"<span style=\"font-weight: bold; color: var(--color-custom-1--95);\">Níže vybíráme pár z X realizovaných zakázek</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 pb--80 pt--60" name={"reference-2"} anim={"2"}>
          
          <ColumnWrap className="column__flex --left el--3 pb--06 pt--06" columns={"3"}>
            
            <ColumnWrapper className="mt--20">
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/e909b97d95704c8e97a9593108d2cc3f_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/39397/e909b97d95704c8e97a9593108d2cc3f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/e909b97d95704c8e97a9593108d2cc3f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/e909b97d95704c8e97a9593108d2cc3f_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":420}} content={"IVECO TRUCK CENTRUM NÝŘANY - Mytí prosklených ploch demineralizovanou vodou"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="mt--20">
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/d92a5e39b24b403dbe5be92c33746d1f_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/39397/d92a5e39b24b403dbe5be92c33746d1f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/d92a5e39b24b403dbe5be92c33746d1f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/d92a5e39b24b403dbe5be92c33746d1f_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":420}} content={"SUPERLAND NOVÉ BUTOVICE - Pravidelné mytí výloh"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="mt--20">
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/52a90426567641f0930fcf1c21b4cc62_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/39397/52a90426567641f0930fcf1c21b4cc62_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/52a90426567641f0930fcf1c21b4cc62_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/52a90426567641f0930fcf1c21b4cc62_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":420}} content={"IVECO TRUCK CENTRUM LODĚNICE - Mytí prosklených ploch"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="mt--20">
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/c87e153189c74fdf8cd1556b9d9f7859_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/39397/c87e153189c74fdf8cd1556b9d9f7859_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/c87e153189c74fdf8cd1556b9d9f7859_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/c87e153189c74fdf8cd1556b9d9f7859_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":420}} content={"SVJ MILÁNSKÁ - Mytí oken společných prostor s bělením rámů oken a dalších ploch z PVC"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="mt--20">
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/70df454b21034c47a8ef94a612d86373_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/39397/70df454b21034c47a8ef94a612d86373_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/70df454b21034c47a8ef94a612d86373_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/70df454b21034c47a8ef94a612d86373_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":420}} content={"SVJ BATELOVSKÁ - Mytí oken společných prostor s bělením rámů oken a dalších ploch z PVC"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="mt--20">
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/b157d1f754e34e79bdfcda95f3238d0e_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/39397/b157d1f754e34e79bdfcda95f3238d0e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/b157d1f754e34e79bdfcda95f3238d0e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/b157d1f754e34e79bdfcda95f3238d0e_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":420}} content={"KŘESLICE - Mytí všech vnitřních a vnějších prosklených ploch rodinného domu"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 pb--80 pt--60" name={"reference-2"} anim={"2"}>
          
          <ColumnWrap className="column__flex --left el--3 pb--06 pt--06" columns={"3"}>
            
            <ColumnWrapper className="mt--20">
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/24cd7a6a65c1438887b24323589340fe_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/39397/24cd7a6a65c1438887b24323589340fe_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/24cd7a6a65c1438887b24323589340fe_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/24cd7a6a65c1438887b24323589340fe_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":420}} content={"BYT 3+KK&nbsp; - PRAHA<br>Mytí oken / lodžie + bělení rámů, parapetů a dalších ploch z PVC&nbsp;"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="mt--20">
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/9cdda1b8c3704aa8979a5b6c09d35b00_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/39397/9cdda1b8c3704aa8979a5b6c09d35b00_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/9cdda1b8c3704aa8979a5b6c09d35b00_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/9cdda1b8c3704aa8979a5b6c09d35b00_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":420}} content={"IVECO LODĚNICE -&nbsp;Oboustranné čištění všech garážových vrat"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="mt--20">
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/d065ff44ac494d3084a1cce239a1e950_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/39397/d065ff44ac494d3084a1cce239a1e950_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/d065ff44ac494d3084a1cce239a1e950_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/d065ff44ac494d3084a1cce239a1e950_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":420}} content={"IVECO TRUCK CENTRUM NÝŘANY - Oboustranné čištění všech garážových vrat"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="mt--20">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 pb--60 pt--60" layout={"l9"} name={"hmmqtfspm46"} anim={"2"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/3af189250a1741cd8ddb84fc1162e957_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":551}} srcSet={"https://cdn.swbpg.com/t/39397/3af189250a1741cd8ddb84fc1162e957_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/3af189250a1741cd8ddb84fc1162e957_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/3af189250a1741cd8ddb84fc1162e957_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39397/3af189250a1741cd8ddb84fc1162e957_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/7a0c1ba0d9424294888c7868311765c1_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":551}} srcSet={"https://cdn.swbpg.com/t/39397/7a0c1ba0d9424294888c7868311765c1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/7a0c1ba0d9424294888c7868311765c1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/7a0c1ba0d9424294888c7868311765c1_s=860x_.jpg 860w"} lightbox={true}>
              </Image>

              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/9f4108bedda04dd7af9a40df63602e76_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":554}} srcSet={"https://cdn.swbpg.com/t/39397/9f4108bedda04dd7af9a40df63602e76_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/9f4108bedda04dd7af9a40df63602e76_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/9f4108bedda04dd7af9a40df63602e76_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/70dfcb2878274e79b2d06b2a3a053b92_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":596}} srcSet={"https://cdn.swbpg.com/t/39397/70dfcb2878274e79b2d06b2a3a053b92_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/70dfcb2878274e79b2d06b2a3a053b92_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/70dfcb2878274e79b2d06b2a3a053b92_s=860x_.jpg 860w"} lightbox={true}>
              </Image>

              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/8d1c71761f4240a6b32dac1d3aac6a2f_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":596}} srcSet={"https://cdn.swbpg.com/t/39397/8d1c71761f4240a6b32dac1d3aac6a2f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/8d1c71761f4240a6b32dac1d3aac6a2f_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 pb--60 pt--60" layout={"l9"} name={"hmmqtfspm46"} anim={"2"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/e5f963f3029a4ee19b58c1ee17f25d0d_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":533}} srcSet={"https://cdn.swbpg.com/t/39397/e5f963f3029a4ee19b58c1ee17f25d0d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/e5f963f3029a4ee19b58c1ee17f25d0d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/e5f963f3029a4ee19b58c1ee17f25d0d_s=860x_.jpg 860w"}>
              </Image>

              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/0b8bc397e6b348649b4eb4d2346953fb_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":533}} srcSet={"https://cdn.swbpg.com/t/39397/0b8bc397e6b348649b4eb4d2346953fb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/0b8bc397e6b348649b4eb4d2346953fb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/0b8bc397e6b348649b4eb4d2346953fb_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/e14e1a4e460240bf8caf72493328cc6b_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":533}} srcSet={"https://cdn.swbpg.com/t/39397/e14e1a4e460240bf8caf72493328cc6b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/e14e1a4e460240bf8caf72493328cc6b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/e14e1a4e460240bf8caf72493328cc6b_s=860x_.jpg 860w"}>
              </Image>

              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/f3d49c332cae49488f6abe113ca756fe_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":533}} srcSet={"https://cdn.swbpg.com/t/39397/f3d49c332cae49488f6abe113ca756fe_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/f3d49c332cae49488f6abe113ca756fe_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/f3d49c332cae49488f6abe113ca756fe_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--0" name={"lw60z5h5vx"}>
          
          <ColumnWrap className="column__flex --shape4 js-anim  --anim2 --center el--2 pb--50 pl--0 pr--0 pt--50 flex--top" anim={"2"} animS={null} style={{"backgroundColor":"var(--color-blend--95)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"} style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--left" content={"Kontaktujte nás a uvidíte svět v novém světle!"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null} style={{"maxWidth":510}}>
              
              <Button className="btn-box btn-box--shape3 btn-box--right" href={"/kontakt"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-custom-1)"}} anim={"2"}>
          
          <ColumnWrap className="column__flex el--2 mb--20 mt--10 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--24 title-box--invert" style={{"maxWidth":""}} content={"Pojďte do toho s námi!"}>
              </Title>

              <Text className="text-box text-box--invert" content={"<span style=\"font-weight: bold;\">Důsledná kontrola\n<br>Férové ceny\n<br>Flexibilita\n<br>Pečlivost</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--24 title-box--invert" style={{"maxWidth":""}} content={"Kontakt"}>
              </Title>

              <Text className="text-box text-box--invert" content={"&nbsp;info@mytiokencz.cz<br>+420 704 434 084<br>"}>
              </Text>

              <Text className="text-box text-box--invert" content={"<a href=\"https://saywebpage.com\">Tvorba web stránek</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}